import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import '../styles/footer.scss';
import CompanyDetails from './company-details';
import { links } from '../config/navigation';
import { SocialMedia } from './social-media';
import FooterAwards from './footer-awards';

const Footer: React.FC = () => {
  const intl = useIntl();

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>MIKINO</h2>
            <p>{intl.formatMessage({ id: 'FOOTER_COMPANY_DESCRIPTION' })}</p>
            <SocialMedia />
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <h2>{intl.formatMessage({ id: 'FOOTER_COMPANY_TITLE' })}</h2>

                <div className="links">
                  {links.map(link => (
                    <Link
                      activeClassName="active"
                      className="link item"
                      key={link.href}
                      to={link.href}
                    >
                      {intl.formatMessage({
                        id: link.localizationKey
                      })}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="col centered-column">
                <h2>{intl.formatMessage({ id: 'FOOTER_CONTACT_US_TITLE' })}</h2>
                <CompanyDetails />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span>
              © {new Date().getFullYear()}
              {` `}
              {intl.formatMessage({ id: 'FOOTER_ALL_RIGHTS_RESERVED' })}
            </span>
          </div>
          <div className="col">
            <div className="archiver">
              <Link className="link item" to="/imprint">
                {intl.formatMessage({
                  id: 'navigation.PAGE_IMPRINT'
                })}
              </Link>
              <Link className="link item" to="/privacy-policy">
                {intl.formatMessage({
                  id: 'PRIVACY_POLICY_LABEL'
                })}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
