import React from 'react';

import '../styles/social-media.scss';

import SvgFacebook from '../images/svgs/social-media/fb.svg';
import SvgYoutube from '../images/svgs/social-media/youtube.svg';
import SvgInstagram from '../images/svgs/social-media/instagram.svg';
import SvgLinkedIn from '../images/svgs/social-media/linkedin.svg';

const socialMedias = [
  {
    href: 'https://www.facebook.com',
    icon: <SvgFacebook />,
    id: 'facebook',
    hidden: true
  },
  {
    href: 'https://www.linkedin.com/company/imagine-heroes',
    icon: <SvgLinkedIn />,
    id: 'linkedin',
    hidden: true
  },
  {
    href: 'https://www.instagram.com/mikino.de/',
    icon: <SvgInstagram />,
    id: 'instagram',
    hidden: false
  },
  {
    href: 'https://www.youtube.com',
    icon: <SvgYoutube />,
    id: 'youtube',
    hidden: true
  }
];

export const SocialMedia = () => (
  <div className="social-media">
    {socialMedias.map(item =>
      item.hidden === true ? null : (
        <a
          key={item.id}
          href={item.href}
          target="_blank"
          aria-label={item.id}
          rel="noopener noreferrer"
        >
          {item.icon}
        </a>
      )
    )}
  </div>
);
