import React, { useState } from 'react';
import { links, LinkItem } from '../config/navigation';
import '../styles/navigation.scss';
import SvgCompanyLogoDark from '../images/svgs/company-logo-dark.svg';
import SvgCompanyLogoLight from '../images/svgs/company-logo-light.svg';
// import LocalesSelector from './locales-selector';
import { Dropdown } from './dropdown';
import { Drawer } from 'antd';

import { useIntl, Link } from 'gatsby-plugin-intl';
import SvgHamburgerMenu from '../images/svgs/hamburger-menu.svg';
import SvgClose from '../images/svgs/close.svg';
import { SocialMedia } from './social-media';
import Button from './button';

export interface NavigationProps {
  modeClassName?: 'dark' | 'light' | 'transparent';
}

const Navigation: React.FC<NavigationProps> = ({ modeClassName = 'light' }) => {
  const [isDrawerOpened, setDrawerOpened] = useState<boolean>(false);

  const intl = useIntl();

  const makeLink = (link: LinkItem) =>
    link.href === '/estimation' ? (
      <Button
        label={intl.formatMessage({ id: 'GET_FREE_ESTIMATION' })}
        modeClassName={modeClassName === 'dark' ? 'primary' : 'dark'}
        href="/contact"
      />
    ) : (
      <Link
        activeClassName="active"
        className="link item"
        aria-label={intl.formatMessage({
          id: link.localizationKey
        })}
        key={link.id}
        to={link.href}
      >
        {intl.formatMessage({
          id: link.localizationKey
        })}
      </Link>
    );

  const companyLogo =
    modeClassName === 'dark' ? <SvgCompanyLogoDark /> : <SvgCompanyLogoLight />;

  return (
    <header className={modeClassName}>
      <nav className="container">
        <div className="logo">
          <Link to="/" aria-label="company logo">
            {companyLogo}
          </Link>
        </div>

        <div className="navigation-desktop">
          {links
            .slice(1)
            .map(link =>
              link.children === undefined ? (
                makeLink(link)
              ) : (
                <Dropdown
                  key={link.id}
                  defaultValue={makeLink(link)}
                  menu={link.children.map(childLink => makeLink(childLink))}
                />
              )
            )}
          {/* <LocalesSelector /> */}
        </div>

        <div className="navigation-mobile">
          <div className="actions">
            <div
              className="hamburger-btn btn"
              tab-index="0"
              onClick={() => setDrawerOpened(true)}
            >
              <SvgHamburgerMenu />
            </div>
          </div>

          <Drawer
            placement="right"
            closable={false}
            width="100%"
            visible={isDrawerOpened}
          >
            <div className="drawer-head">
              <div>
                <Link to="/" aria-label="company logo">
                  {companyLogo}
                </Link>
              </div>

              <div
                className="close-btn btn"
                tab-index="0"
                onClick={() => setDrawerOpened(false)}
              >
                <SvgClose />
              </div>
            </div>

            <div className="drawer-body">
              {/* <LocalesSelector /> */}
              {links.map(link => makeLink(link))}
              <SocialMedia />
            </div>
          </Drawer>
        </div>
      </nav>
    </header>
  );
};

export default Navigation;
