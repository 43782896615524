import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import '../styles/company-details.scss';

const CompanyDetails = () => {
  const intl = useIntl();

  return (
    <div className="company-details">
      <div className="group">
        <div className="group">
          <h4>Unsere Telefonnummer</h4>
          <a href="tel:+493083216920">+49 30 832 16 920</a>
        </div>
        <h4>
          {intl.formatMessage({ id: 'COMPANY_DETAILS_HELLO_GROUP_TITLE' })}
        </h4>
        <a href="mailto:info@mikino.de">info@mikino.de</a>
      </div>
    </div>
  );
};

export default CompanyDetails;
