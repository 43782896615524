import React from 'react';
import { Dropdown as AntDropdown } from 'antd';
import '../styles/dropdown.scss';
import SvgArrowDown from '../images/svgs/arrow-down.svg';

interface Props {
  defaultValue: string | React.ReactNode;
  menu: React.ReactNode;
  trigger?: ('click' | 'hover' | 'contextMenu')[];
  placement?:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
}
export const Dropdown: React.FC<Props> = ({
  defaultValue,
  menu,
  placement,
  trigger
}) => (
  <AntDropdown
    trigger={trigger ?? ['hover']}
    placement={placement ?? 'bottomCenter'}
    overlay={<div className="dropdown-items">{menu}</div>}
  >
    <div className="dropdown-default-item">
      {defaultValue}
      <SvgArrowDown />
    </div>
  </AntDropdown>
);

export default Dropdown;
