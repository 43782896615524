import '../styles/layout.scss';

import React, { useEffect } from 'react';

import aos from '../imports/aos';
import Footer from './footer';
import Navigation, { NavigationProps } from './navigation';
import { BackTop } from 'antd';

interface Props extends NavigationProps {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children, modeClassName }) => {
  useEffect(() => {
    aos();
  }, []);

  return (
    <>
      <Navigation modeClassName={modeClassName} />
      {children}
      <BackTop />
      <Footer />
    </>
  );
};

export default Layout;
