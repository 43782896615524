import '../styles/button.scss';

import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { Link } from 'gatsby-plugin-intl';
import React from 'react';

import win from '../imports/window';

interface Props extends ButtonProps {
  disabled?: boolean;
  href?: string;
  hrefOptions?: { target: string; rel: string };
  label: string;
  loading?: boolean;
  modeClassName?: 'dark' | 'primary';
}

const Button: React.FC<Props> = ({
  label,
  modeClassName = 'dark',
  href = null,
  hrefOptions,
  ...rest
}) => (
  <AntdButton {...rest} className={modeClassName}>
    {href ? (
      hrefOptions ? (
        <a href={href} {...hrefOptions}>
          {label}
        </a>
      ) : (
        <Link
          to={href}
          state={{
            prevPath: win?.location?.pathname
          }}
        >
          {label}
        </Link>
      )
    ) : (
      label
    )}
  </AntdButton>
);

export default Button;
