import { generateUniqId } from './../utils/generate-uniq-id';
export interface LinkItem {
  children?: Array<LinkItem>;
  href: string;
  id: string;
  localizationKey: string;
}

export const links: LinkItem[] = [
  {
    href: '/',
    id: generateUniqId(),
    localizationKey: 'navigation.PAGE_HOME_TITLE'
  },
  {
    href: '/fences',
    id: generateUniqId(),
    localizationKey: 'navigation.PAGE_FENCES_TITLE'
  },
  {
    href: '/about',
    id: generateUniqId(),
    localizationKey: 'navigation.PAGE_ABOUT_TITLE'
  },
  {
    href: '/contact',
    id: generateUniqId(),
    localizationKey: 'navigation.PAGE_CONTACT_TITLE'
  }
];
